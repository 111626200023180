.footer-widget {
    margin-bottom: 50px !important;
    &,
    .widget {
        padding: 0;
        border: none;
        padding-bottom: 0;
        background-color: transparent;
        box-shadow: none;
    }
    .wp-block-search__label,
    .widget_title {
        color: $white-color;
        border-bottom: none;
        margin: -0.04em 0 45px 0;
        position: relative;
        &:after {
            display: none;
        }
    }

    &.widget_meta,
    &.widget_pages,
    &.widget_archive,
    &.widget_recent_entries,
    &.widget_categories,
    &.widget_nav_menu {
        .menu,
        > ul {
            margin-bottom: -5px;
        }
        a {
            font-size: 18px;
            font-weight: 400;
            padding: 0;
            font-family: $title-font;
            color: $body-color;
            display: block;
            max-width: 100%;
            width: max-content;
            background-color: transparent;
            border: none;
            position: relative;
            &:hover {
                background-color: transparent;
                color: $theme-color;
            }
        }

        li {
            border: 0 !important;
            padding-bottom: 0 !important;
            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.widget-about {
    .footer-logo {
        margin-bottom: 37px;
    }
    .about-text {
        max-width: 350px;
        margin-top: -0.65em;
        margin-bottom: 27px;
    }
}
.widget-contact {
    margin-top: -0.5em;
    p {
        color: $body-color;
        font-size: 18px;
        font-weight: 400;
        font-family: $title-font;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        a {
            color: $body-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
}
.widget-contact-grid {
    display: flex;
    gap: 15px;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: 25px;
    }
    .icon {
        height: 40px;
        width: 40px;
        line-height: 40px;
        border: 1px solid $gray-color;
        border-radius: 50%;
        flex: none;
        color: $white-color;
        text-align: center;
    }
    p {
        font-size: 18px;
        font-weight: 400;
        font-family: $title-font;
        color: $white-color;
        margin-bottom: 0;
        margin-top: -0.3em;
    }
    h6 {
        font-size: 20px;
        font-weight: 700;
        color: $white-color;
        margin-bottom: -0.3em;
        a {
            color: $white-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
}
.widget-newsletter {
    background: rgba($color: #ffffff, $alpha: 0.04);
    border-radius: 5px;
    padding: 35px 40px;
    max-width: 410px;
}
.footer-text {
    margin-top: -0.45em;
    margin-bottom: 16px;
    color: $body-color;
    font-size: 18px;
    font-weight: 400;
    font-family: $title-font;

}
.newsletter-form {
    position: relative;
    display: flex;
    .form-group {
        margin-bottom: 0;
        width: 100%;
        input {
            height: 60px;
            padding: 0 18px 0 20px;
            background: transparent;
            border: 1px solid rgba($color: #e1e1e1, $alpha: 0.3);
            border-right: 0;
            border-radius: 5px 0 0 5px;
        }
    }
    .btn {
        flex: none;
        border-radius: 0 5px 5px 0 !important;
        width: 60px;
        padding:  0 20px;
        height: 62px;
        top: -1px;
    }
    &.style2 {
        .form-group {
            input {
                border: 2px solid rgba($color: #e1e1e1, $alpha: 1);
                border-right: 0;
                color: $white-color;
                &::placeholder {
                    color: $white-color;
                }
            }
        }
        .btn {
            width: auto;
            padding: 0 30px;
        }
        @include xs {
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            .form-group input {
                border-right: 2px solid $white-color;
                border-radius: 5px;
            }
            .btn {
                border-radius: 5px !important;
            }
        }
    }
}

@include sm {
    .footer-widget {
        .widget_title {
            margin-bottom: 35px;
        }
    }
    .footer-widget.widget_meta a, .footer-widget.widget_pages a, .footer-widget.widget_archive a, .footer-widget.widget_recent_entries a, .footer-widget.widget_categories a, .footer-widget.widget_nav_menu a {
        font-size: 16px;
        margin-bottom: 20px;
    }
}
@include xs {
    .widget-newsletter {
        padding: 30px;
        max-width: 410px;
    }
}